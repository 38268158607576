
.hk-markdown {
	&__header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 1px;
	}
	&__preview {
		display: inline-block;
		min-height: 56px;
		margin-bottom: 28px;
		background-color: rgba(225, 225, 225, 0.05);
		padding: 16px 12px 6px 12px;
		width: 100%;
	}
}
[data-theme="light"] {
	.hk-markdown__header {
		background-color: rgba(0, 0, 0, 0.03);
	}
}
